import { Grid, Typography } from '@mui/material';
import theme from 'core/theme';
import { FC, useMemo } from 'react';
import { DataRaptorRule } from 'store/dataRaptorRule/types';
import { RuleSmartRecommendation } from './RuleSmartRecommendation';
import { AI_RECOMMENDATION_TYPE, FieldRecommendation } from 'store/dataAnomaly/types';

export interface DataValidationAnomalyDetailProps {
  summary: string;
  migrationId: string;
  rule: DataRaptorRule;
  fieldRecommendations?: FieldRecommendation[];
  actionRecommendations?: string[];
  node: any;
}

export const DataValidationAnomalyDetail: FC<DataValidationAnomalyDetailProps> = (
  props: DataValidationAnomalyDetailProps,
) => {
  const { summary, migrationId, rule, node, fieldRecommendations, actionRecommendations } = props;

  const aiRecommendationKey = useMemo(() => {
    return `${node.label}#${node.id}#${rule.ruleId}#${AI_RECOMMENDATION_TYPE.DATA_VALIDATION}`;
  }, [node.id, node.label, rule.ruleId]);

  return (
    <Grid>
      <Grid container sx={{ gap: 1 }}>
        {/* Detail */}
        <Grid container sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Grid item>
            <Typography sx={{ color: theme.palette.neutral.n400, lineHeight: '16px', fontSize: '12px' }}>
              Details
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ lineHeight: '16px', fontSize: '14px', whiteSpace: 'pre-wrap' }}>{summary}</Typography>
          </Grid>
          <Grid item>
            <RuleSmartRecommendation
              recommendationId={aiRecommendationKey}
              summary={[summary]}
              migrationId={migrationId}
              rule={rule}
              node={node}
              fieldRecommendations={fieldRecommendations}
              actionRecommendations={actionRecommendations}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
