import { Box, CircularProgress, Typography } from '@mui/material';
import { DataAnomalyCard } from './components/DataAnomalyCard/DataAnomalyCard';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDataAnomaly } from 'store/dataAnomaly/hook';
import { set } from 'lodash';
import { DataRaptorRule } from 'store/dataRaptorRule/types';
import { AI_RECOMMENDATION_TYPE, GetRecordAnomaliesResponse } from 'store/dataAnomaly/types';

interface DataAnomalyMenuViewProps {
  node: any;
  referencedUsers: any;
  migrationId: string;
}

interface RuleMap {
  [x: string]: DataRaptorRule;
}

export const DataAnomaly: FC<DataAnomalyMenuViewProps> = (props) => {
  const { node, migrationId } = props;
  const [anomalies, setAnomalies] = useState<GetRecordAnomaliesResponse>();
  const [rulesToPull, setRulesToPull] = useState<string[]>([]);
  const [rulesMap, setRulesMap] = useState<RuleMap>({});
  const [currentNodeId, setCurrentNodeId] = useState<string>();

  const { getRecordAnomalies, getRuleByIds } = useDataAnomaly();

  const nodeChanged = useMemo(() => currentNodeId !== node.id, [currentNodeId, node.id]);

  const loading = useMemo(() => nodeChanged, [nodeChanged]);

  useEffect(() => {
    const { id, label } = node;
    getRecordAnomalies({
      migrationId,
      tableName: label,
      recordId: id,
      onSuccess: (data) => {
        if (data && data.anomalies && data.anomalies.length > 0) {
          setAnomalies(data);
          const ruleIds = data.anomalies.map((d) => d.rule_id);
          setRulesToPull(ruleIds);
        } else {
          setAnomalies(undefined);
          setRulesToPull([]);
          setCurrentNodeId(node.id);
          setRulesMap({});
        }
      },
    });
  }, [getRecordAnomalies, migrationId, node]);

  useEffect(() => {
    if (rulesToPull.length > 0) {
      getRuleByIds({
        migrationId,
        rulesIds: rulesToPull,
        onSuccess: (data) => {
          const map = data?.reduce((acc: RuleMap, cur) => {
            return set(acc, cur.ruleId, cur);
          }, {});
          setRulesMap(map);
          setCurrentNodeId(node.id);
        },
      });
    }
  }, [getRuleByIds, migrationId, node.id, rulesToPull]);

  const getAnomalyList = useCallback(() => {
    return (
      anomalies?.anomalies.map((anomaly) => {
        const rule = rulesMap[anomaly.rule_id];
        if (!rule) return null;
        const key = `${node.label}#${node.id}#${rule.ruleId}#${
          anomaly.ai_recommendation_type || AI_RECOMMENDATION_TYPE.DATA_ANOMALY
        }`;
        const aiRecommendation = anomalies?.aiRecommendations.find((aiRecommendation) => aiRecommendation.Id === key);
        return (
          <DataAnomalyCard
            key={`data-anomaly-card-${anomaly.id}`}
            aiRecommendation={aiRecommendation}
            rule={rule}
            anomaly={anomaly}
            node={node}
            migrationId={migrationId}
          />
        );
      }) || []
    );
  }, [anomalies, migrationId, node, rulesMap]);

  const AnomalyList = useMemo(() => getAnomalyList(), [getAnomalyList]);

  return (
    <>
      {loading === true && (
        <Box sx={{ textAlign: 'center', my: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }} gap={1}>
          <CircularProgress size={'20px'} />
          <Typography>Loading Anomalies...</Typography>
        </Box>
      )}

      {loading !== true && AnomalyList}

      {loading !== true && AnomalyList.length === 0 && (
        <Box sx={{ textAlign: 'center', my: 1 }}>
          <Typography>No Anomalies Found</Typography>
        </Box>
      )}
    </>
  );
};
