import { FC, useMemo } from 'react';
import { FieldRecommendation } from 'store/dataAnomaly/types';
import { Grid } from '@mui/material';
import { FieldRecommendationCard } from './FieldRecommendationCard';

interface FieldRecommendationListProps {
  fieldRecommendations: FieldRecommendation[];
  node: any;
  migrationId: string;
  recommendationId: string;
  summary: string;
}
export const ACCOUNT_ID_FIELDS = ['CreatedById', 'LastModifiedById', 'OwnerId'];
export const ACCOUNT_DATE_FIELDS = ['CreatedDate', 'LastModifiedDate', 'SystemModstamp'];

const AVOID_RECOMMENDATION_FIELDS = [
  'Id',
  'RecordTypeId',
  'OwnerId',
  'CreatedById',
  'LastModifiedById',
  'CreatedDate',
  'LastModifiedDate',
  'SystemModstamp',
  'LastActivityDate',
  'LastViewedDate',
];

export const FieldRecommendationList: FC<FieldRecommendationListProps> = ({
  fieldRecommendations,
  node,
  migrationId,
  recommendationId,
  summary,
}) => {
  console.log({ node });

  const filteredFieldRecommendations = useMemo(() => {
    return fieldRecommendations.filter((recommendation) => {
      return (
        recommendation.value &&
        recommendation.value != null &&
        recommendation.value.trim() != '' &&
        !AVOID_RECOMMENDATION_FIELDS.includes(recommendation.fieldName)
      );
    });
  }, [fieldRecommendations]);

  const recommendationsByField = useMemo(() => {
    return filteredFieldRecommendations.reduce((acc, recommendation) => {
      if (!acc[recommendation.fieldName]) {
        acc[recommendation.fieldName] = [];
      }
      acc[recommendation.fieldName].push(recommendation);
      return acc;
    }, {} as Record<string, FieldRecommendation[]>);
  }, [filteredFieldRecommendations]);

  const fieldNames = useMemo(() => Object.keys(recommendationsByField), [recommendationsByField]);

  const content = useMemo(
    () =>
      fieldNames.map((fieldName) => (
        <FieldRecommendationCard
          key={fieldName}
          fieldName={fieldName}
          fieldRecommendations={recommendationsByField[fieldName]}
          node={node}
          migrationId={migrationId}
          recommendationId={recommendationId}
          summary={summary}
        />
      )),
    [fieldNames, migrationId, node, recommendationId, recommendationsByField, summary],
  );

  return (
    <>
      <Grid>{content}</Grid>
    </>
  );
};
