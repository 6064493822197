import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import theme from 'core/theme';
import { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { getDataAnomalyFieldRecommendation } from 'http/ai';
import { DataRaptorRule } from 'store/dataRaptorRule/types';
import { SmartRecommendationField } from './SmartRecommendationField';
import { SmartRecommendationLoading } from '../shared/SmartRecommendationLoading';
import { useSnackbar } from 'notistack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { RecommendationFeedBack } from './RecommendationFeedBack';
import { AI_RECOMMENDATION_TYPE } from 'store/dataAnomaly/types';
import { DataAnomalyCardMenu } from '../shared/DataAnomalyCardMenu/DataAnomalyCardMenu';
import { useDataAnomalyCardMenuHook } from '../shared/DataAnomalyCardMenu/useDataAnomalyCardMenuHook';

export interface DataAnomalyCardFieldDetailProps {
  fieldName: string;
  details: string[];
  currentValue: string;
  recommendation?: string;
  migrationId: string;
  rule: DataRaptorRule;
  node: any;
  recommendationFeedBack?: boolean;
}

export const DataAnomalyCardFieldDetail: FC<DataAnomalyCardFieldDetailProps> = (props) => {
  const { currentValue, details, fieldName, recommendation, migrationId, rule, node, recommendationFeedBack } = props;
  const [loadedRecommendation, setLoadedRecommendation] = useState<string>();
  const [loadingRecommendation, setLoadingRecommendation] = useState<boolean>(false);
  const [menuActionLoading, setMenuActionLoading] = useState<boolean>(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [acceptedValue, setAcceptedValue] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const { handleOpenCreateActivityMenu, handleAcceptRecommendation } = useDataAnomalyCardMenuHook();

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleMenuToggle = useCallback((event?: SyntheticEvent<any>) => {
    if (event) {
      setMenuAnchorEl(event.currentTarget);
    }
    setMenuOpen((open) => !open);
  }, []);

  const recommendationCalculated = useMemo(() => {
    return loadedRecommendation || recommendation;
  }, [loadedRecommendation, recommendation]);

  const haveRecommendation = useMemo(() => {
    return recommendationCalculated && recommendationCalculated.trim().length > 0;
  }, [recommendationCalculated]);

  const aiRecommendationKey = useMemo(() => {
    return `${node.label}#${node.id}#${rule.ruleId}#${AI_RECOMMENDATION_TYPE.DATA_ANOMALY}`;
  }, [node.id, node.label, rule.ruleId]);

  const handleOpenCreateActivityMenuWrapper = useCallback(() => {
    handleOpenCreateActivityMenu(node, details, {
      smartRecommendation: `Recommended Values: ${recommendationCalculated}`,
      callback: handleMenuClose,
    });
  }, [details, handleMenuClose, handleOpenCreateActivityMenu, node, recommendationCalculated]);

  const onSuccessAcceptRecommendation = useCallback(
    (value: string) => {
      handleMenuClose();
      setAcceptedValue(value);
    },
    [handleMenuClose],
  );

  const handleAcceptRecommendationWrapper = useCallback(() => {
    if (!haveRecommendation || !recommendationCalculated) {
      return;
    }
    handleAcceptRecommendation(migrationId, aiRecommendationKey, fieldName, recommendationCalculated, {
      setMenuActionLoading,
      callback: () => onSuccessAcceptRecommendation(recommendationCalculated),
    });
  }, [
    aiRecommendationKey,
    fieldName,
    handleAcceptRecommendation,
    haveRecommendation,
    migrationId,
    onSuccessAcceptRecommendation,
    recommendationCalculated,
  ]);

  const handleGetAnomalyFieldRecommendation = useCallback(() => {
    setLoadingRecommendation(true);
    getDataAnomalyFieldRecommendation(migrationId, {
      ruleId: rule.ruleId,
      record: node,
      details: [fieldName],
      recordId: node.id,
      tableName: node.label,
      fieldName: fieldName,
    })
      .then((res) => {
        console.log('AI Field Recommendation res', res);
        const fieldNameRecommendation =
          res.recommendation.fieldRecommendations.find((recommendation) => recommendation.fieldName === fieldName)
            ?.value || res.recommendation.fieldRecommendations[0]?.value;

        setLoadedRecommendation(fieldNameRecommendation);
      })
      .catch((err) => {
        enqueueSnackbar('Failed to get recommendation', { variant: 'error' });
        console.log('AI Field Recommendation err', err);
      })
      .finally(() => setLoadingRecommendation(false));
  }, [enqueueSnackbar, fieldName, migrationId, node, rule.ruleId]);

  const DetailComponent = useMemo(() => {
    if (details.length === 1) {
      return <Typography sx={{ lineHeight: '16px', fontSize: '14px', whiteSpace: 'pre-wrap' }}>{details}</Typography>;
    } else {
      return (
        <ul style={{ margin: 0, paddingInlineStart: '20px' }}>
          {details.map((detail, index) => (
            <li key={`${index}`}>
              <Typography sx={{ lineHeight: '16px', fontSize: '14px', whiteSpace: 'pre-wrap' }}>{detail}</Typography>
            </li>
          ))}
        </ul>
      );
    }
  }, [details]);
  return (
    <>
      <Grid
        container
        sx={{ border: `0.3rem solid ${theme.palette.lightBg.main}`, borderRadius: '4px', padding: '0.3rem', gap: 1 }}
      >
        <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Grid item>
              <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>{fieldName}</Typography>
            </Grid>
          </Box>
          <Box>
            <IconButton onClick={handleMenuToggle} sx={{ p: 0, m: 0 }}>
              <MoreHorizIcon />
            </IconButton>
          </Box>
        </Grid>

        {/* Detail */}
        <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item>
            <Typography sx={{ color: theme.palette.neutral.n400, lineHeight: '16px', fontSize: '12px' }}>
              Details
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ lineHeight: '16px', fontSize: '14px', whiteSpace: 'pre-wrap' }}>
              {DetailComponent}
            </Typography>
          </Grid>
        </Grid>
        {/* Value */}
        <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item>
            <Typography sx={{ color: theme.palette.neutral.n400, lineHeight: '16px', fontSize: '12px' }}>
              Current Value
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ lineHeight: '16px', fontSize: '14px' }}>
              {acceptedValue || currentValue || 'Null'}{' '}
            </Typography>
          </Grid>
        </Grid>
        {/* Smart Recommendation */}
        <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item>
            {loadingRecommendation === true ? (
              <SmartRecommendationLoading />
            ) : (
              <SmartRecommendationField
                handleGetAnomalyFieldRecommendation={handleGetAnomalyFieldRecommendation}
                recommendationCalculated={recommendationCalculated}
              />
            )}
          </Grid>
        </Grid>
        {/* Divider */}
        <Divider sx={{ width: '100%' }} />
        {/* Recommendation Feedback */}
        {haveRecommendation ? (
          <RecommendationFeedBack
            recommendationId={aiRecommendationKey}
            recommendationFeedBack={recommendationFeedBack}
            migrationId={migrationId}
          />
        ) : (
          <></>
        )}
      </Grid>
      <DataAnomalyCardMenu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        handleClose={handleMenuClose}
        handleAcceptRecommendation={handleAcceptRecommendationWrapper}
        handleCreateTask={handleOpenCreateActivityMenuWrapper}
        actionLoading={menuActionLoading}
      />
    </>
  );
};
