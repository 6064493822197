import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { DataAnomalyCardFieldDetail } from './components/DataAnomalyCardFieldDetail/DataAnomalyCardFieldDetail';
import { getDataAnomalySummary } from 'http/ai';

import theme from 'core/theme';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DataRaptorRule } from 'store/dataRaptorRule/types';
import {
  AiRecommendation,
  DataAnomalyFieldDetail,
  DataAnomalyRecord,
  FieldRecommendation,
  RuleTypeEnum,
} from 'store/dataAnomaly/types';
import { DataValidationAnomalyDetail } from './components/DataValidationAnomalyDetail/DataValidationAnomalyDetail';

export interface DataAnomalyCardProps {
  migrationId: string;
  rule: DataRaptorRule;
  anomaly: DataAnomalyRecord;
  node: any;
  aiRecommendation?: AiRecommendation;
}

export interface DataAnomalyFieldDetailDescriptionMerged {
  field: string;
  value: string;
  description: string[];
}

export const DataAnomalyCard: FC<DataAnomalyCardProps> = (props) => {
  const { rule, anomaly, node, migrationId, aiRecommendation } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [summary, setSummary] = useState<string>();
  const [fieldRecommendations, setFieldRecommendations] = useState<FieldRecommendation[] | undefined>(undefined);
  const [actionRecommendations, setActionRecommendations] = useState<string[] | undefined>(undefined);

  const handleGetAnomalySummary = useCallback(() => {
    const details = anomaly?.details?.details?.map((detail) => detail.description) || [];
    getDataAnomalySummary(migrationId, {
      ruleId: rule.ruleId,
      record: node,
      details: details,
      recordId: node.id,
      tableName: node.label,
    })
      .then((res) => {
        setSummary(res.summary);
      })
      .catch((err) => {
        console.log('AI Summary err', err);
      })
      .finally(() => setLoading(false));
  }, [anomaly, migrationId, node, rule.ruleId]);

  useEffect(() => {
    if (aiRecommendation?.payload?.fieldRecommendations) {
      setFieldRecommendations(aiRecommendation.payload.fieldRecommendations);
    }
    if (aiRecommendation?.payload?.actionRecommendations) {
      setActionRecommendations(aiRecommendation.payload.actionRecommendations);
    }
  }, [aiRecommendation]);

  useEffect(() => {
    if (aiRecommendation?.payload?.summary?.length ?? 0 > 0) {
      setSummary(aiRecommendation?.payload?.summary);
      setLoading(false);
    } else {
      handleGetAnomalySummary();
    }
  }, [aiRecommendation?.payload?.summary, handleGetAnomalySummary]);

  const getFieldDetailList = useCallback(() => {
    if (loading === true) {
      return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      );
    }
    const detailMap: { [x: string]: DataAnomalyFieldDetailDescriptionMerged } = {};
    const detailsCopy: DataAnomalyFieldDetail[] = JSON.parse(JSON.stringify(anomaly?.details?.details || []));
    detailsCopy.forEach((detail) => {
      if (!detailMap[detail.field]) {
        detailMap[detail.field] = { ...detail, description: [detail.description] };
      } else {
        detailMap[detail.field].description.push(detail.description);
      }
    });

    return Object.keys(detailMap).map((fieldName, index) => {
      return (
        <DataAnomalyCardFieldDetail
          key={`data-anomaly-card-field-detail${index}`}
          fieldName={detailMap[fieldName].field}
          details={(summary?.length || 0) > 0 ? [summary || ''] : detailMap[fieldName].description}
          currentValue={detailMap[fieldName].value}
          recommendation={fieldRecommendations?.find((recommendation) => recommendation.fieldName === fieldName)?.value}
          migrationId={migrationId}
          rule={rule}
          node={node}
          recommendationFeedBack={aiRecommendation?.feedback}
        />
      );
    });
  }, [
    aiRecommendation?.feedback,
    anomaly?.details?.details,
    fieldRecommendations,
    loading,
    migrationId,
    node,
    rule,
    summary,
  ]);

  const getDataValidationAnomalyDetail = useCallback(() => {
    if (loading === true) {
      return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      );
    }
    return (
      <DataValidationAnomalyDetail
        summary={summary || ''}
        migrationId={migrationId}
        rule={rule}
        node={node}
        fieldRecommendations={fieldRecommendations}
        actionRecommendations={actionRecommendations}
      />
    );
  }, [actionRecommendations, fieldRecommendations, loading, migrationId, node, rule, summary]);

  const content = useMemo(() => {
    if (anomaly.anomalyType === RuleTypeEnum.AnomalyDetection) {
      return getFieldDetailList();
    } else {
      return getDataValidationAnomalyDetail();
    }
  }, [anomaly.anomalyType, getDataValidationAnomalyDetail, getFieldDetailList]);

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column', padding: '5px' }}>
      <Grid
        container
        sx={{
          display: 'flex',
          border: `5px solid ${theme.palette.lightBg.main}`,
          borderRadius: '4px',
          padding: '0.5rem',
          gap: '0.4rem',
        }}
      >
        {/* Header */}
        <Grid container sx={{ display: 'flex' }} justifyContent={'space-between'} alignItems={'start'}>
          <Grid item flex={5}>
            <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>{rule.name}</Typography>
          </Grid>
        </Grid>
        {/* Divider */}
        <Divider sx={{ width: '100%' }} />
        <Grid container sx={{ display: 'flex', gap: 2 }}>
          {content}
        </Grid>
      </Grid>
    </Grid>
  );
};
