import { Box, Grid, IconButton, Typography } from '@mui/material';
import { FC, SyntheticEvent, useCallback, useState } from 'react';
import { CardContentWrapper } from '../ui';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DataAnomalyCardMenu } from '../shared/DataAnomalyCardMenu/DataAnomalyCardMenu';
import { useDataAnomalyCardMenuHook } from '../shared/DataAnomalyCardMenu/useDataAnomalyCardMenuHook';

interface ActionRecommendationListProps {
  actionRecommendations: string[];
  migrationId: string;
  recommendationId: string;
  summary: string;
  node: any;
}

export const ActionRecommendationsList: FC<ActionRecommendationListProps> = ({
  actionRecommendations,
  node,
  summary,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuActionLoading, setMenuActionLoading] = useState<boolean>(false);

  const { handleOpenCreateActivityMenu } = useDataAnomalyCardMenuHook();

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleMenuToggle = useCallback((event?: SyntheticEvent<any>) => {
    if (event) {
      setMenuAnchorEl(event.currentTarget);
    }
    setMenuOpen((open) => !open);
  }, []);

  const handleOpenCreateActivityMenuWrapper = useCallback(() => {
    const recommendedValues = actionRecommendations.join('\n * ');
    handleOpenCreateActivityMenu(node, [summary], {
      smartRecommendation: `\nRecommended Values: \n *${recommendedValues}`,
      callback: handleMenuClose,
    });
  }, [actionRecommendations, handleMenuClose, handleOpenCreateActivityMenu, node, summary]);

  return (
    <CardContentWrapper>
      <Grid sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="labelRegular12" sx={{ fontWeight: 'bold' }}>
            Recommended Actions:
          </Typography>
          <IconButton onClick={handleMenuToggle} sx={{ p: 0, m: 0 }}>
            <MoreHorizIcon />
          </IconButton>
        </Grid>

        <ul>
          {actionRecommendations.map((actionRecommendation, index) => (
            <li key={index}>
              <Typography variant="labelRegular12">{actionRecommendation}</Typography>
            </li>
          ))}
        </ul>
      </Grid>
      <DataAnomalyCardMenu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        handleClose={handleMenuToggle}
        disableAcceptRecommendation={true}
        handleCreateTask={handleOpenCreateActivityMenuWrapper}
        actionLoading={menuActionLoading}
      />
    </CardContentWrapper>
  );
};
