import { DataRaptorRule } from 'store/dataRaptorRule/types';
import { ActionPromiseArgs, ActionState } from 'store/types';

export enum AI_RECOMMENDATION_TYPE {
  DATA_ANOMALY = 'data_anomaly',
  DATA_VALIDATION = 'data_validation',
}

export interface DataAnomalyFieldDetail {
  field: string;
  value: string;
  description: string;
}

interface DataAnomalyDetail {
  details: DataAnomalyFieldDetail[];
}

export interface GetRecordAnomaliesResponse {
  anomalies: DataAnomalyRecord[];
  aiRecommendations: AiRecommendation[];
}

export interface FieldRecommendation {
  value: string;
  fieldName: string;
  precisionScore: number;
  enrichmentRecordId?: string;
}

export interface AiRecommendationPayload {
  fieldRecommendations: FieldRecommendation[];
  actionRecommendations: string[];
  summary: string;
}

export interface AiRecommendation {
  Id: string;
  table_name: string;
  record_id: string;
  aux_compose_key: string;
  type: string;
  feedback?: boolean;
  payload: AiRecommendationPayload;
  created_at: string;
}

export enum RuleTypeEnum {
  DataValidation = 'data-validation',
  AnomalyDetection = 'anomaly-detection',
}

export interface DataAnomalyRecord {
  id: string;
  table_name: string;
  record_id: string;
  rule_id: string;
  record: { [x: string]: any };
  details: DataAnomalyDetail;
  anomalyType: RuleTypeEnum;
  ai_recommendation_type: AI_RECOMMENDATION_TYPE;
  ai_recommendations: { [x: string]: any };
}

export interface DataAnomalyState {
  error: boolean | string;
  success: boolean | string;
  loading: boolean;
  data: {
    getRecordAnomaliesState: ActionState<GetRecordAnomaliesResponse>;
    recordAnomaliesCache: { [x: string]: GetRecordAnomaliesResponse };
    getRuleByIdsState: ActionState<DataRaptorRule[]>;
  };
}

export interface getRecordAnomaliesArgs extends ActionPromiseArgs<GetRecordAnomaliesResponse> {
  migrationId: string;
  tableName: string;
  recordId: string;
}

export interface getRuleByIdsArgs extends ActionPromiseArgs<DataRaptorRule[]> {
  migrationId: string;
  rulesIds: string[];
  fields?: string[];
}

export interface DataAnomalyStateHook extends DataAnomalyState {
  getRecordAnomalies: (args: getRecordAnomaliesArgs) => void;
  getRuleByIds: (args: getRuleByIdsArgs) => void;
}
