import { Divider, Grid } from '@mui/material';
import { EnrichmentRecordType, getDataAnomalyFieldRecommendation } from 'http/ai';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useMemo, useState } from 'react';
import { SmartRecommendationLoading } from '../shared/SmartRecommendationLoading';
import { SmartRecommendationField } from '../DataAnomalyCardFieldDetail/SmartRecommendationField';
import { DataRaptorRule } from 'store/dataRaptorRule/types';
import { FieldRecommendation } from 'store/dataAnomaly/types';
import { Show } from '../../../../../../../../../../../../../../components/show';
import { RecommendationFeedBack } from '../DataAnomalyCardFieldDetail/RecommendationFeedBack';
import { ActionRecommendationsList } from './ActionRecommendationList';
import { FieldRecommendationList } from './FieldRecommendationList/FieldRecommendationList';

export interface RuleSmartRecommendationProps {
  summary: string[];
  recommendationId: string;
  migrationId: string;
  fieldRecommendations?: FieldRecommendation[];
  actionRecommendations?: string[];
  rule: DataRaptorRule;
  node: any;
}

export const RuleSmartRecommendation: FC<RuleSmartRecommendationProps> = ({
  summary,
  migrationId,
  rule,
  node,
  fieldRecommendations,
  actionRecommendations,
  recommendationId,
}) => {
  const [loadingRecommendation, setLoadingRecommendation] = useState<boolean>(false);
  const [loadedRecommendations, setLoadedRecommendations] = useState<FieldRecommendation[] | undefined>(undefined);
  const [loadedActionRecommendations, setLoadedActionRecommendations] = useState<string[] | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const calculatedFieldRecommendations = useMemo(() => {
    return fieldRecommendations || loadedRecommendations;
  }, [fieldRecommendations, loadedRecommendations]);

  const calculatedActionRecommendations = useMemo(() => {
    return actionRecommendations || loadedActionRecommendations;
  }, [actionRecommendations, loadedActionRecommendations]);

  console.log({ calculatedActionRecommendations, calculatedFieldRecommendations });

  const haveRecommendation = useMemo(() => {
    return Array.isArray(calculatedFieldRecommendations) || Array.isArray(calculatedActionRecommendations);
  }, [calculatedActionRecommendations, calculatedFieldRecommendations]);

  console.log('node:', node);

  const handleGetAnomalyFieldRecommendation = useCallback(() => {
    setLoadingRecommendation(true);
    getDataAnomalyFieldRecommendation(migrationId, {
      ruleId: rule.ruleId,
      record: node,
      details: summary,
      recordId: node.id,
      tableName: node.label,
      enrichmentContext: { name: node.Name || `${node.FirstName} ${node.LastName}` },
      enrichmentRecordType: node.label === 'Account' ? EnrichmentRecordType.COMPANY : EnrichmentRecordType.PROFILE,
    })
      .then((res) => {
        console.log('AI Field Recommendation res', res);
        setLoadedRecommendations(res.recommendation.fieldRecommendations);
        setLoadedActionRecommendations(res.recommendation.actionRecommendations);
      })
      .catch((err) => {
        enqueueSnackbar('Failed to get recommendation', { variant: 'error' });
        console.log('AI Field Recommendation err', err);
      })
      .finally(() => setLoadingRecommendation(false));
  }, [enqueueSnackbar, migrationId, node, rule.ruleId, summary]);

  return (
    <>
      {/* Smart Recommendation */}
      <Grid container sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Grid item>
          {loadingRecommendation === true && <SmartRecommendationLoading />}
          {loadingRecommendation === false && !haveRecommendation && (
            <SmartRecommendationField
              handleGetAnomalyFieldRecommendation={handleGetAnomalyFieldRecommendation}
              recommendationCalculated={
                (calculatedFieldRecommendations && calculatedFieldRecommendations[0]?.value) || ''
              }
            />
          )}
        </Grid>
        {/* Field Recommendation List */}
        <Grid>
          <Show when={Array.isArray(calculatedFieldRecommendations)}>
            <FieldRecommendationList
              fieldRecommendations={calculatedFieldRecommendations || []}
              node={node}
              migrationId={migrationId}
              recommendationId={recommendationId}
              summary={summary[0]}
            />
          </Show>
        </Grid>
        {/* Actions Recommendation List */}
        <Grid>
          <Show when={Array.isArray(calculatedActionRecommendations)}>
            <ActionRecommendationsList
              actionRecommendations={calculatedActionRecommendations || []}
              migrationId={migrationId}
              recommendationId={recommendationId}
              summary={summary[0]}
              node={node}
            />
          </Show>
        </Grid>
        {/* Divider */}
        <Divider sx={{ width: '100%' }} />
        {/* AI Recommendation Feedback */}
        <Show when={haveRecommendation}>
          <RecommendationFeedBack
            recommendationId={recommendationId}
            migrationId={migrationId}
          ></RecommendationFeedBack>
        </Show>
      </Grid>
    </>
  );
};
