import { FC, SyntheticEvent, useCallback, useState } from 'react';
import { FieldRecommendation } from 'store/dataAnomaly/types';
import { CardContentWrapper } from '../../ui';
import { Box, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as PercentageWheelIconRed } from 'assets/icons/graph/percentageWheelIconRed.svg';
import { ReactComponent as PercentageWheelIconGreen } from 'assets/icons/graph/percentageWheelIconGreen.svg';
import { ReactComponent as PercentageWheelIconOrange } from 'assets/icons/graph/percentageWheelIconOrange.svg';
import theme from 'core/theme';
import { TextButton } from 'components/ui';
import { Show } from 'components/show';
import { DataAnomalyCardMenu } from '../../shared/DataAnomalyCardMenu/DataAnomalyCardMenu';
import { useDataAnomalyCardMenuHook } from '../../shared/DataAnomalyCardMenu/useDataAnomalyCardMenuHook';

interface FieldRecommendationCardProps {
  fieldName: string;
  fieldRecommendations: FieldRecommendation[];
  node: any;
  migrationId: string;
  recommendationId: string;
  summary: string;
}

export const FieldRecommendationCard: FC<FieldRecommendationCardProps> = ({
  fieldName,
  fieldRecommendations,
  node,
  migrationId,
  recommendationId,
  summary,
}) => {
  const [open, setOpen] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [acceptedValue, setAcceptedValue] = useState<string | null>(null);
  const [menuActionLoading, setMenuActionLoading] = useState<boolean>(false);
  const [recommendedValue, setRecommendedValue] = useState<string | null>(null);
  const { handleAcceptRecommendation, handleOpenCreateActivityMenu } = useDataAnomalyCardMenuHook();

  const getPercentageWheelIcon = useCallback((percentage: number) => {
    console.log({ percentage });
    if (percentage < 50) {
      return PercentageWheelIconRed;
    } else if (percentage < 80) {
      return PercentageWheelIconOrange;
    } else {
      return PercentageWheelIconGreen;
    }
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleMenuToggle = useCallback((event?: SyntheticEvent<any>) => {
    if (event) {
      setMenuAnchorEl(event.currentTarget);
    }
    setMenuOpen((open) => !open);
  }, []);

  const onIconMenuClick = useCallback(
    (event: SyntheticEvent<any>, value: string) => {
      handleMenuToggle(event);
      setRecommendedValue(value);
    },
    [handleMenuToggle],
  );

  const handleOpenCreateActivityMenuWrapper = useCallback(() => {
    const recommendedValues = fieldRecommendations.map((recommendation) => recommendation.value).join(', ');
    handleOpenCreateActivityMenu(node, [summary], {
      smartRecommendation: `Recommended Values: ${recommendedValues}`,
      callback: handleMenuClose,
    });
  }, [fieldRecommendations, handleMenuClose, handleOpenCreateActivityMenu, node, summary]);

  const onSuccessAcceptRecommendation = useCallback(
    (value: string) => {
      handleMenuClose();
      setAcceptedValue(value);
    },
    [handleMenuClose],
  );

  const handleAcceptRecommendationWrapper = useCallback(() => {
    if (recommendedValue == null) {
      return;
    }
    handleAcceptRecommendation(migrationId, recommendationId, fieldName, recommendedValue, {
      setMenuActionLoading,
      callback: () => onSuccessAcceptRecommendation(recommendedValue),
    });
  }, [
    fieldName,
    handleAcceptRecommendation,
    migrationId,
    onSuccessAcceptRecommendation,
    recommendationId,
    recommendedValue,
  ]);

  console.log({ fieldRecommendations });

  const currentValue = node[fieldName] || 'null';
  console.log({ fieldRecommendations });
  return (
    <CardContentWrapper sx={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item>
          <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>{fieldName}</Typography>
        </Grid>
      </Grid>

      <Divider sx={{ width: '100%' }} />

      <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item>
          <Typography sx={{ color: theme.palette.neutral.n400, lineHeight: '16px', fontSize: '12px' }}>
            Current Value
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ lineHeight: '16px', fontSize: '14px' }}>
            {acceptedValue || currentValue || 'Null'}{' '}
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ color: theme.palette.neutral.n400, lineHeight: '16px', fontSize: '12px' }}>
          Smart Recommendation
        </Typography>
        <TextButton
          sx={{ color: theme.palette.primary.main, lineHeight: '16px', fontSize: '12px', fontWeight: 'bold' }}
          variant="text"
          onClick={() => setOpen((value) => !value)}
        >
          {open ? 'Close' : 'Open'}
        </TextButton>
      </Box>
      <Show when={open}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.subtone3,
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '4px',
            p: 1,
            position: 'relative',
          }}
        >
          <Show when={menuActionLoading}>
            <Tooltip
              title={'Please wait while we are updating the current value with the selected recommendation'}
              followCursor={true}
            >
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  zIndex: 1,
                  top: 0,
                  left: 0,
                  backgroundColor: 'RGBA(255,255,255,0.6)',
                }}
              />
            </Tooltip>
          </Show>

          <Grid sx={{ display: 'flex', justifyContent: 'center', zIndex: 0 }}>
            <Box>
              <TextButton
                disabled={page == 0}
                sx={{ color: theme.palette.primary.main, lineHeight: '16px', fontSize: '14px', fontWeight: 'bold' }}
                onClick={() => setPage((page) => page - 1)}
              >
                {'<< Prev. 3'}
              </TextButton>
            </Box>
            <Box>
              <TextButton
                disabled={fieldRecommendations.length <= 3 || page == 1}
                sx={{ color: theme.palette.primary.main, lineHeight: '16px', fontSize: '14px', fontWeight: 'bold' }}
                onClick={() => setPage((page) => page + 1)}
              >
                {'Next 3 >>'}
              </TextButton>
            </Box>
          </Grid>
          <Grid container sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {fieldRecommendations.slice(page * 3, page * 3 + 3).map((recommendation, index) => {
              const verified = recommendation.enrichmentRecordId != null;
              const PercentageWheelIcon = getPercentageWheelIcon(recommendation.precisionScore || 0);
              const value = recommendation.value;
              return (
                <Grid
                  key={index}
                  item
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      {verified ? (
                        <CheckCircleIcon sx={{ fontSize: 'small', fill: theme.palette.green.main }} />
                      ) : (
                        <WarningIcon sx={{ fontSize: 'small', fill: theme.palette.orange.main }} />
                      )}
                      <Typography variant="labelRegular12">{verified ? 'Verified' : 'Unverified'}</Typography>
                    </Box>
                    <Box>
                      <IconButton onClick={(e) => onIconMenuClick(e, value)} sx={{ p: 0, m: 0 }}>
                        <MoreHorizIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ lineHeight: '16px', fontSize: '14px' }}>{recommendation.value}</Typography>
                    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <PercentageWheelIcon style={{ position: 'absolute', bottom: 2, right: -10 }} />
                      <Typography sx={{ lineHeight: '16px', fontSize: '12px' }}>
                        {recommendation.precisionScore || 0}
                        {'%'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Show>
      <DataAnomalyCardMenu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        handleClose={handleMenuToggle}
        handleAcceptRecommendation={handleAcceptRecommendationWrapper}
        handleCreateTask={handleOpenCreateActivityMenuWrapper}
        actionLoading={menuActionLoading}
      />
    </CardContentWrapper>
  );
};
