import { useGraphRender } from 'store/graphRender/hooks';
import { useCreateTaskFormStore } from 'store/createTaskForm/hooks';
import { acceptAIRecommendation, RecommendationActionEnum } from 'http/ai';
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

export const useDataAnomalyCardMenuHook = () => {
  const { setAIDetail, setRequestDetails, setRequestRecord, setRequestTableName, setRequestRecordId } =
    useCreateTaskFormStore();
  const { setShowCreateActivityModal } = useGraphRender();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenCreateActivityMenu = useCallback(
    (node: any, details: string[], options: { smartRecommendation?: string; callback: () => void }) => {
      const requestDetailsArray = details;
      if (options.smartRecommendation) {
        requestDetailsArray.push(`Smart Recommendation: ${options.smartRecommendation}`);
      }
      setAIDetail(details[0]);
      setShowCreateActivityModal(true);
      setRequestRecord(node);
      setRequestTableName(node.label);
      setRequestRecordId(node.id);
      setRequestDetails(requestDetailsArray);
      if (options.callback) {
        options.callback();
      }
    },
    [
      setAIDetail,
      setRequestDetails,
      setRequestRecord,
      setRequestRecordId,
      setRequestTableName,
      setShowCreateActivityModal,
    ],
  );

  const handleAcceptRecommendation = useCallback(
    (
      migrationId: string,
      aiRecommendationKey: string,
      fieldName: string,
      value: string,
      options: { setMenuActionLoading?: (value: React.SetStateAction<boolean>) => void; callback?: () => void },
    ) => {
      options.setMenuActionLoading && options.setMenuActionLoading(true);
      acceptAIRecommendation(migrationId, aiRecommendationKey, fieldName, RecommendationActionEnum.ACCEPT, value)
        .then(() => {
          enqueueSnackbar('Recommendation accepted', { variant: 'success' });
          options.callback && options.callback();
        })
        .catch((err) => {
          enqueueSnackbar('Failed to accept recommendation', { variant: 'error' });
          console.log('AI Field Recommendation err', err);
        })
        .finally(() => options.setMenuActionLoading && options.setMenuActionLoading(false));
    },
    [enqueueSnackbar],
  );

  return { handleOpenCreateActivityMenu, handleAcceptRecommendation };
};
